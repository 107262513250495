<template>
  <div id="app">
    <!-- 用户 -->
    <bannerall></bannerall>
    <div class="balance_btn">
      <div class="balance_title">
        <div class="balance_text">账户余额(元)</div>
        <div class="balance_total">{{ formatAmount(balance) }}</div>
      </div>
      <div style="display: flex; justify-content: space-around">
        <div class="moneyTag">
          <div class="tagname">
            {{ freedomBalanceDetail && productBundleBalance.toFixed(2) }}
          </div>
          <div class="tagPrice">
            餐费包<span>({{ canFreedomCashout ? "可" : "不可" }}提现)</span>
          </div>
        </div>
        <div class="moneyTag">
          <div class="tagname">
            {{ (freedomBalanceDetail.freedomBalance || 0).toFixed(2) }}
          </div>
          <div class="tagPrice">
            零钱<span>({{ canProductBundleCashout ? "可" : "不可" }}提现)</span>
          </div>
          <!-- jumpApply
  jumpRecharge -->
        </div>
      </div>
      <div class="btn">
        <van-button
          class="cashout"
          v-if="isshowNewspaperMeals"
          type="default"
          @click="jumpNewspaperMeal"
          >报餐</van-button
        >
        <van-button
          class="cashout"
          v-if="productbundleBuy"
          type="default"
          @click="checkPayMethods(1)"
          >餐包缴费</van-button
        >
        <van-button
          class="cashout"
          v-if="freedomBuy"
          type="default"
          @click="checkPayMethods(2)"
          >零钱充值</van-button
        >
        <van-button type="default" @click="jumpCashout">提现</van-button>
      </div>
    </div>
    <div class="balance_account">
      <div class="balance_account_title">
        <div class="balance_account_text">余额明细</div>
        <div class="balance_account_more">
          <router-link
            class="more"
            :to="
              'account_loose_detail?businessCustomerUserId=' +
              customerUser.businessCustomerUserId
            "
          >
            更多>>
          </router-link>
        </div>
      </div>
      <div
        class="balance_account_title loose"
        v-show="
          freedomBalanceDetail.freedomBalance &&
          freedomBalanceDetail.freedomBalance != 0
        "
      >
        <div class="balance_account_loose">零钱</div>
        <div class="balance_account_loose">
          {{
            freedomBalanceDetail.freedomBalance &&
            freedomBalanceDetail.freedomBalance.toFixed(2)
          }}
        </div>
      </div>
      <div
        class="balance_account_title loose"
        v-show="
          freedomBalanceDetail.prepaidCardBalance &&
          freedomBalanceDetail.prepaidCardBalance != 0
        "
      >
        <div class="balance_account_loose">储值卡</div>
        <div class="balance_account_loose">
          {{
            freedomBalanceDetail.prepaidCardBalance &&
            freedomBalanceDetail.prepaidCardBalance.toFixed(2)
          }}
        </div>
      </div>
      <!-- <van-cell-group  v-for="item in freedomBalanceDetail.userPocketBalanceList"> -->
      <!-- <van-cell  style="width: 93vw;" :title="item.name" :value="(item.balance).toFixed(2)" :label="'就餐时间'+formattimestamp1(item.cycleStart)+'至'+formattimestamp1(item.cycleEnd)" /> -->

      <!-- </van-cell-group> -->
      <div
        class="userPocketBalanceList"
        :key="index"
        :class="
          index ==
          (freedomBalanceDetail.userPocketBalanceList &&
            freedomBalanceDetail.userPocketBalanceList.length - 1)
            ? 'bordernone'
            : ''
        "
        v-for="(item, index) in freedomBalanceDetail.userPocketBalanceList"
      >
        <div class="userPocketBalanceListText">
          <div>{{ item.name }}</div>
          <div>{{ item.balance.toFixed(2) }}</div>
        </div>
        <div>
          {{
            "就餐时间" +
            formattimestamp1(item.cycleStart) +
            "至" +
            formattimestamp1(item.cycleEnd)
          }}
        </div>
      </div>
      <van-empty
        v-if="
          freedomBalanceDetail.userPocketBalanceList &&
          freedomBalanceDetail.userPocketBalanceList.length == 0 &&
          freedomBalanceDetail.freedomBalance == 0 &&
          freedomBalanceDetail.prepaidCardBalance == 0
        "
        description="暂无信息"
      />
    </div>
    <div style="margin: 12px">
      <router-link
        class="to_transaction"
        :to="
          'transaction_log?businessCustomerUserId=' +
          customerUser.businessCustomerUserId
        "
      >
        <div class="transaction_text">交易记录</div>
        <div>></div>
      </router-link>
    </div>

    <!-- <div class="bg_green"> -->
    <!-- <div class="white_box3"> -->
    <!-- <div class="white_box3_in"> -->
    <!-- 用户 -->

    <!-- <div class="user pd_3p6_0 clear" v-if="customerUser.businessCustomerUser">
            <div class="user_avatar larger" :style="{backgroundImage:`url(${img2})`}"></div>
            <div class="user_msg">
              <span>{{ customerUser.businessCustomerUser.name }}</span>
              <label>{{
                  customerUser.businessCustomerUser.business.name
                }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
            </div>
          </div> -->
    <!-- 余额 -->
    <!-- <div v-if="showAccountBalance && showAccountBalance.setValue === '1'">
            <div
              :class="{ hide: showBalance == true }"
              class="balance"
              style="display: flex; margin-bottom: 8px"
            >
              <button class="button_green mrg_0_auto" @click="getBalance">
                查看账户余额
              </button>
              <button
                class="button_green mrg_0_auto"
                v-if="
                  currentCustomerUser.businessId ===
                    '017b972ae06b003b2c96c1f37b970f88' ||
                  currentCustomerUser.businessId ===
                    '017b9122d74000742c96c1f47b90d56f'
                "
                @click="getPrepaidCardBalance"
              >
                查看红包余额
              </button>
            </div>
            <div class="balance" v-show="showBalance">
              <div class="balance_t">账户余额</div>
              <div class="balance_b">
                <ul class="clear">
                  <li>{{ formatAmount(totalBalance) }}</li>
                  <li>
                    <router-link
                      :to="
                        'transaction_log?businessCustomerUserId=' +
                        customerUser.businessCustomerUserId
                      "
                    >
                      交易记录 <span class="iconfont">&#xe615;</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="balance" v-show="showCardBalance">
              <div class="balance_t">红包余额</div>
              <div class="balance_b">
                <ul class="clear">
                  <li>{{ cardBalance }}</li>
                  <li>
                    <router-link
                      :to="
                        'transaction_log?businessCustomerUserId=' +
                        customerUser.businessCustomerUserId
                      "
                    >
                      交易记录 <span class="iconfont">&#xe615;</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->

    <!-- <div class="white_box3 mgt_3vw"> -->
    <!-- <div class="white_box3_in"> -->
    <!-- 副标题 -->
    <!-- <div class="sub_title">当前消费用户</div> -->
    <!-- 用户 -->
    <!-- <div
          class="user under_line pd_3p6_0 clear"
          @click="jumpCustomerUseAccount(currentCustomerUser)"
          v-if="currentCustomerUser.businessCustomerUser"
        >
          <div
            class="user_avatar"
            :style="{ backgroundImage: `url(${img})` }"
          ></div>
          <div class="user_msg">
            <span>{{ currentCustomerUser.businessCustomerUser.name }}</span>
            <label
              >{{ currentCustomerUser.businessCustomerUser.business.name }}，{{
                currentCustomerUser.businessCustomerUser.structure.name
              }}</label
            >
          </div>
        </div> -->

    <!-- 副标题 -->
    <!-- <div class="sub_title mgt_4vw">其他消费用户</div> -->

    <!-- 用户 -->
    <!-- <div
          class="user under_line pd_3p6_0 clear"
          v-for="(option, index) in customerUserOptions"
          :key="index"
          @click="jumpCustomerUseAccount(option)"
        >
          <div
            class="user_avatar"
            :style="{ backgroundImage: `url(${img})` }"
          ></div>
          <div class="user_msg">
            <span v-if="option.businessCustomerUser">{{
              option.businessCustomerUser.name
            }}</span>
            <label v-if="option.businessCustomerUser"
              >{{ option.businessCustomerUser.business.name }}，{{
                option.businessCustomerUser.structure.name
              }}</label
            >
          </div>
        </div> -->

    <!-- 按钮 -->
    <!-- <div class="two_btn"> -->
    <!-- <ul class="clear"> -->
    <!-- <li>
              <button class="button_green" @click="jumpCashout">提现</button>
            </li> -->
    <!--            <li>-->
    <!--              <button class="button_green" @click="jumpApply">报名</button>-->
    <!--            </li>-->
    <!-- <li>
              <button class="button_orange" @click="jumpRecharge">充值</button>
            </li> -->
    <!-- </ul> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical
        >加载中...</van-loading
      >
    </div>
    <!-- <div class="loading_all" v-if="isshowNewspaperMeals==null&&productbundleBuy==null&&freedomBuy==null">
      <van-loading type="spinner" color="#ffffff" vertical
        >加载中...</van-loading
      >
    </div> -->
  </div>
</template>

<style scoped lang="less">
.moneyTag {
  width: 40%;
  border-radius: 8px;
  margin-bottom: 12px;
  .tagname {
    font-size: 18px;
    text-align: center;
    margin-bottom: 4px;
  }
  .tagPrice {
    text-align: center;
  }
}
.user {
  width: initial;
  margin: 12px;
  border-radius: 8px;
}
.top {
  background: #fff;
  color: #000;
}
.user_r {
  padding: 0px 6px;
  border-radius: 8px;
  border: 1px solid #5aaa4e;
  color: #5aaa4e;
  font-size: 13px;
}
.btn {
  display: flex;
  justify-content: space-between;
}
.van-button {
}
.balance_btn {
  margin: 12px;
  background: #fff;
  border-radius: 8px;
  padding: 12px;
}
.balance_title {
  margin: 12px 0 12px 0;
}
.balance_text {
  font-size: 12px;
  text-align: center;
}
.balance_total {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 10px;
}
.van-button--default {
  border-radius: 5px !important;
}
.van-button {
  flex: 1;
  margin: 5px;
  padding: 0 8px;
  font-size: 12px;
}
.cashout {
  flex: 1;
  margin: 5px;
  padding: 0 8px;
  border: 1px solid @primary-color;
  color: @primary-color;
  background-color: @linear-color3;
}
.balance_account {
  margin: 12px;
  padding: 12px 12px;
  background: #fff;
  border-radius: 8px;
}
.balance_account_title {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}
.balance_account_text {
  font-weight: bold;
  padding-left: 14px;
}
.balance_account_more {
  color: #a9a8a8;
  margin-right: 12px;
}
.more {
  color: #000;
}
a:hover {
  color: #000;
}
.loose {
  padding: 4vw;
}
.to_transaction {
  display: flex;
  justify-content: space-between;
  padding: 3vw 8vw 3vw 5vw;
  background: #fff;
  border-radius: 8px;
}
::v-deep.van-cell__label {
  // color: red;
  width: 60vw;
}
.userPocketBalanceList {
  padding: 4vw;
  // padding-bottom: 0;
  border-bottom: 1px solid #e5e5e5;
}
.userPocketBalanceListText {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.bordernone {
  border: none;
}
</style>

<script>
import Vue from "vue";
import { ACCESS_CustomerUser } from "@/store/mutation-types";
import { post } from "@/utils/http";
import Notify from "vant/es/notify";
import { formatAmount, formattimestamp } from "../../utils/util";
import { Dialog, Toast, Button, Loading, Empty } from "vant";
import switch_user from "../index/switch_user.vue";
import bannerall from "../components/banner_all.vue";
Vue.use(Button).use(Empty).use(Loading);
// 注意，是查看消费者的钱包，而不是客户的钱包
export default {
  components: { switch_user, bannerall },
  data() {
    return {
      freedomBalanceDetail: {},
      img: require("../../assets/images/avatar.png"),
      img2: require("../../assets/images/avatar2.png"),
      showBalance: false,

      loading: false,
      customerUserOptions: [],
      customerUser: {},
      currentCustomerUser: {},
      totalBalance: 0,
      customerAccount: {},
      customerAccountBank: {},
      showAccountBalance: {},

      cardBalance: 0,
      showCardBalance: false,
      paymentInfo: null,
      changeShow: false,
      balance: 0,
      primary_color: primary_color2,
      isshowNewspaperMeals: null,
      productbundleBuy: null,
      freedomBuy: null,
      // 餐费余额
      productBundleBalance: 0,
      canFreedomCashout: 0,
      canProductBundleCashout:0
    };
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }

    this.currentCustomerUser = Vue.ls.get(ACCESS_CustomerUser);
    this.switchUser(this.currentCustomerUser);
    console.log(this.currentCustomerUser);
    if (this.currentCustomerUser.businessCustomerUser.wasGraduation != 0) {
      this.$router.replace({ path: "/index" });
    }

    this.loading = true;

    // this.requestFreedomBalance()

    Promise.all([
      this.requestSetting(),
      this.requestshowmMeals(),
      this.requestfreedomBalanceDetail(),
      this.requestcustomerUserOptions(),
      this.requestcustomerAccount(),
    ]).then(([res1, res2, res3, res4, res5]) => {
      this.loading = false;
    });
  },
  methods: {
    checkPayMethods(number) {
      // 增加限额控制
      // 检查是否二类户
      post(
        "api/customer/account/v2/{businessCustomerUserId}/find".replace(
          "{businessCustomerUserId}",
          this.customerUser.businessCustomerUserId
        ),
        {},
        true
      )
        .then((res) => {
          console.log(99);
          this.customerAccountBank = res.data;

          if (this.paymentInfo.code === "GF") {
            if (!this.customerAccountBank.id) {
              this.$router.push({ path: "/AccountAgreement" });
              return;
            }
            // 广发
            if (this.customerAccountBank.accountOpenType === "3") {
              // 检查影像状态？
              // 初审成功
              if (
                this.customerAccountBank.auditStatus &&
                this.customerAccountBank.auditStatus !== "03"
              ) {
                this.loading = false;
                Dialog.alert({
                  title: "温馨提示",
                  message:
                    "您已成功提交资料进行升级，\n广发银行将于1-3个工作日内完成审核，\n审核结果以短信通知，敬请留意。",
                });
                return;
              } else if (
                this.customerAccountBank.auditStatus &&
                this.customerAccountBank.auditStatus === "03"
              ) {
                Dialog.confirm({
                  title: "温馨提示",
                  message: "账户升级失败，请您再次升级或联系客服人员",
                  confirmButtonText: "升级",
                })
                  .then(() => {
                    this.$router.push({ path: "/AccountUpgradeDescription" });
                  })
                  .catch(() => {});
                return;
              }
              if (number == 1) {
                    this.jumpApply();
                  } else {
                    this.jumpRecharge();
                  }
              // // 1. 获取广发余额
              // post("api/guangfa/v2/queryAccountBalance", {})
              //   .then((res) => {
              //     let balance = toDecimal(res.data.eAccAmt);
              //     // 2. 根据 充值金额+广发余额 是否 > 2000 进行比较
              //     if (toDecimal(this.showAmount) + balance > 2000) {
              //       // 3. 大于2000 提示升级，进入升级流程
              //       this.loading = false;
              //       Dialog.confirm({
              //         title: "温馨提示",
              //         message: "您当前的充值限额为2000,\n需升级后才能正常使用",
              //         confirmButtonText: "升级",
              //       })
              //         .then(() => {
              //           this.$router.push({
              //             path: "/AccountUpgradeDescription",
              //           });
              //           return;
              //         })
              //         .catch(() => {
              //           return;
              //         });
              //     }
                 
              //   })
              //   .catch((error) => {
              //     Notify({ type: "warning", message: error.message });
              //     this.loading = false;
              //   });
            } else if (this.customerAccountBank.accountOpenType === "2") {
              if (this.customerAccountBank.auditStatus === "03") {
                if (number == 1) {
                  this.jumpApply();
                } else {
                  this.jumpRecharge();
                }
                // 二审成功
                return;
              }
              if (this.customerAccountBank.auditStatus === "04") {
                // 二审失败
                this.$router.push({
                  path: "/AccountCreateFeedback",
                  query: {
                    type: "UPGRADE_FAIL",
                    msg: this.customerAccountBank.auditRemark,
                  },
                });
              } else if (
                this.customerAccountBank.auditStatus === "05" ||
                this.customerAccountBank.auditStatus === "06"
              ) {
                // 等待影相再审核
                this.$router.push({
                  path: "/AccountCreateFeedback",
                  query: { type: "ID_CARD_REVIEWING" },
                });
              }
              this.loading = false;
            } 
          }else{
            if (number == 1) {
                    this.jumpApply();
                  } else {
                    this.jumpRecharge();
                  }
          }
        
        })
        .catch((err) => {
          Notify({ type: "warning", message: "渠道异常，请重试22" });
        });
    },
    requestcustomerAccount() {
      return post("api/customer/account/v2/find", {}, true)
        .then((res) => {
          this.customerAccount = res.data;
        })
        .catch(() => {});
    },
    requestcustomerUserOptions() {
      return post("api/customer/user/v2/search", {})
        .then((res) => {
          res.data.rows.forEach((item) => {
            if (item.id != this.currentCustomerUser.id) {
              this.customerUserOptions.push(item);
            }
          });
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    requestSetting() {
      return post(
        "api/business/v2/{businessId}/setting/search".replace(
          "{businessId}",
          this.customerUser.businessId
        ),
        {
          size: 500,
        }
      )
        .then((res) => {
          // console.log(res);
          this.productbundleBuy =
            res.data.rows.find((item) => item.setKey === "PRODUCTBUNDLE_BUY")
              .setValue != 0; // 是否允许购买餐包
          this.freedomBuy =
            res.data.rows.find((item) => item.setKey === "FREEDOM_BUY")
              .setValue != 0; // 是否允许购买自由消费
          this.canFreedomCashout =
            res.data.rows.find((item) => item.setKey === "FROZEN").setValue !=
            0; // 是否开启餐费包提现
            this.canProductBundleCashout =
            res.data.rows.find((item) => item.setKey === "ONLY_UNFROZEN_FREEDOM").setValue !=
            0; // 是否开启零钱提现
        })
        .catch((err) => {
          Notify({ type: "warning", message: error.message });
          this.loading = false;
        });
    },
    requestshowmMeals() {
      return post(
        `/api/business/v2/${this.customerUser.businessId}/product/bundle/${this.customerUser.businessCustomerUserId}/signUp/show`
      ).then((res) => {
        this.isshowNewspaperMeals = res.data;
      });
    },
    jumpNewspaperMeal() {
      this.$router.push({ path: "/recharge/newspapermeals" });
    },
    requestfreedomBalanceDetail() {
      return post(
        `/api//business/v2/${this.customerUser.businessId}/business/customer/user/${this.customerUser.businessCustomerUserId}/statistics`
      ).then((res) => {
        console.log(res);
        this.freedomBalanceDetail = res.data;
        let total = 0;
        if (res.data.userPocketBalanceList.length != 0) {
          total = res.data.userPocketBalanceList.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue.balance;
            },
            0
          );
          this.productBundleBalance = total;
        }
        this.balance=total+res.data.freedomBalance+res.data.prepaidCardBalance
      });
    },
    // requestFreedomBalance(){
    //   this.loading=true
    //   post(`/api//business/v2/${this.customerUser.businessId}/business/customer/user/${this.customerUser.businessCustomerUserId}/balance`)
    //   .then(res=>{
    //     this.balance=res.data.freedomBalance
    //     this.loading=false

    //   })
    // },
    goBack() {
      this.$router.push("/index");
      window.history.pushState("forward", null, "");
      window.history.forward(1);
    },
    // 注意，这个只是当前页面切换，并不是切换当前用户
    switchUser(data) {
      if (!data) return;
      this.customerUser = data;
      this.showBalance = false;
      this.loadBusinessPaymentInfo();
    },
    jumpCashout() {
      if (!this.paymentInfo) {
        return;
      }
      if (this.paymentInfo.needPassword === "0") {
        this.$router.push({ path: "/cashout" });
        return;
      }

      this.loading = true;
      post(
        "api/customer/account/v2/:businessCustomerUserId/find".replace(
          ":businessCustomerUserId",
          this.customerUser.businessCustomerUserId
        ),
        {},
        true
      )
        .then((res) => {
          this.customerAccount = res.data;

          if (!this.customerAccount || !this.customerAccount.id) {
            this.$router.push({ path: "/AccountAgreement" });
            return;
          }
          // 根据不同平台进行判断
          if (
            this.customerAccount.auditStatus == "YL" &&
            this.paymentInfo.code === "GF"
          ) {
            this.$router.push({ path: "/AccountAgreement" });
            return;
          }

          if (!this.checkAccountState()) {
            return;
          }
          this.$router.push({ path: "/cashout" });

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    jumpApply() {
      this.$router.push({ path: "/recharge/applyRechargeList" });
    },
    jumpRecharge() {
      if (!this.paymentInfo) {
        return;
      }
      // TODO:临时弹窗
      // let nowDate = new Date();
      // if (this.paymentInfo.code === 'GF' && nowDate.getTime() > 1682956800000 && nowDate.getTime() < 1683129600000) {
      //   Dialog.alert({
      //     title: '温馨提示',
      //     message: '您好！为提供更优质的服务，广发银行将进行系统升级，时间为2023 年 5 月 2 日 00:00 至 5 月 3 日 24:00，届时一天膳事平台中缴费相关功能板块将暂时无法使用，2023 年 5 月 4 日 0 点起恢复正常。',
      //     confirmButtonText: '我知道了',
      //     confirmButtonColor: '#34AC40'
      //   }).then(() => {
      //
      //   });
      //   return;
      // }

      if (this.paymentInfo.needPassword === "0") {
        this.$router.push({ path: "/recharge" });
        return;
      }

      this.loading = true;
      post(
        "api/customer/account/v2/:businessCustomerUserId/find".replace(
          ":businessCustomerUserId",
          this.customerUser.businessCustomerUserId
        ),
        {},
        true
      )
        .then((res) => {
          this.customerAccount = res.data;

          if (this.paymentInfo.code === "GF") {
            if (!this.customerAccount.id) {
              this.$router.push({ path: "/AccountAgreement" });
              return;
            } else if (!this.checkAccountState()) {
              return;
            }
          }

          this.$router.push({ path: "/recharge" });

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkAccountState() {
      if (this.customerAccount.auditStatus === "04") {
        // 二审失败
        this.$router.push({
          path: "/AccountCreateFeedback",
          query: {
            type: "UPGRADE_FAIL",
            msg: this.customerAccount.auditRemark,
          },
        });
        return false;
      } else if (this.customerAccount.auditStatus === "05") {
        // 等待影相再审核
        this.$router.push({
          path: "/AccountCreateFeedback",
          query: { type: "ID_CARD_REVIEWING" },
        });
        return false;
      }
      return true;
    },
    jumpCustomerUseAccount(data) {
      this.loading = true;
      // 获取消费者的渠道信息
      post(
        "api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace(
          "{businessId}",
          data.businessId
        )
      )
        .then((result) => {
          const currentPaymentInfo = result.data;
          // 根据不同渠道信息进行跳转
          post(
            "api/customer/account/v2/:businessCustomerUserId/find".replace(
              ":businessCustomerUserId",
              data.businessCustomerUserId
            ),
            {},
            true
          )
            .then((res) => {
              const currentCustomerAccount = res.data;

              if (currentPaymentInfo.code === "GF") {
                if (!currentCustomerAccount.id) {
                  this.$router.push({
                    path: "/bankSwitch",
                    query: { backUrl: "customer_user_account" },
                  });
                  return;
                }
                if (currentCustomerAccount.auditStatus === "04") {
                  // 二审失败
                  this.$router.push({
                    path: "/AccountCreateFeedback",
                    query: {
                      type: "UPGRADE_FAIL",
                      msg: currentCustomerAccount.auditRemark,
                    },
                  });
                  return;
                } else if (currentCustomerAccount.auditStatus === "05") {
                  // 等待影相再审核
                  this.$router.push({
                    path: "/AccountCreateFeedback",
                    query: { type: "ID_CARD_REVIEWING" },
                  });
                  return;
                }
              }

              this.$router.push({
                path: "/business_customer_user_free_amount",
                query: { businessCustomerUserId: data.businessCustomerUserId },
              });

              this.loading = false;
            })
            .catch((error) => {
              Toast(error.message);
              this.loading = false;
            });
        })
        .catch((error) => {
          Toast(error.message);
          this.loading = false;
        });
    },
    jumpOldSystem() {
      window.location.href = "https://wx.xzb360.com/xzb/home";
    },
    getPrepaidCardBalance() {
      this.loading = true;
      post(
        "api//business/v2/" +
          this.customerUser.businessId +
          "/business/customer/user/" +
          this.customerUser.businessCustomerUserId +
          "/user/pocket/search",
        {
          filters: [{ name: "type", op: "=", value: "PREPAID_CARD" }],
        }
      )
        .then((res) => {
          if (res && res.data && res.data.rows && res.data.rows.length > 0) {
            this.cardBalance = res.data.rows[0].balance;
          } else {
            this.cardBalance = 0;
          }

          this.showCardBalance = true;
          this.showBalance = false;
          this.loading = false;
        })
        .catch((error) => {
          Notify({ type: "warning", message: error.message });
          this.loading = false;
        });
    },
    getBalance() {
      // if (!this.customerAccount || !this.customerAccount.id) {
      //   this.$router.push({path: '/bankSwitch', query: {backUrl: 'customer_user_account'}})
      //   return;
      // }
      //
      // this.loading = true
      // post('api/guangfa/v2/queryAccountBalance', {}).then(res => {
      //   this.totalBanlance = res.data.eAccAmt
      //   this.showBalance = true
      //   this.showCardBalance = false;
      //   this.loading = false
      // }).catch(error => {
      //   Notify({type: 'warning', message: error.message});
      //   this.loading = false
      // })
      this.loading = true;
      post(
        "api/business/v2/{businessId}/business/customer/user/{businessCustomerUserId}/user/pocket/search"
          .replace("{businessId}", this.customerUser.businessId)
          .replace(
            "{businessCustomerUserId}",
            this.customerUser.businessCustomerUserId
          ),
        {
          filters: [],
          page: 0,
          size: 100,
        }
      )
        .then((res) => {
          this.totalBalance = 0;
          if (res && res.data && res.data.rows && res.data.rows.length > 0) {
            for (let i = 0; i < res.data.rows.length; i++) {
              let userPocket = res.data.rows[i];
              this.totalBalance += userPocket.balance;
            }
          }
          if (this.totalBalance < 0) {
            Notify({
              type: "warning",
              message: "余额显示异常，请联系客服处理，谢谢",
            });
          }

          this.showCardBalance = false;
          this.showBalance = true;
          this.loading = false;
        })
        .catch((error) => {
          Notify({ type: "warning", message: error.message });
          this.loading = false;
        });
    },
    loadBusinessPaymentInfo() {
      post(
        "api/business/v2/{businessId}/setting/search".replace(
          "{businessId}",
          this.customerUser.businessId
        ),
        {
          size: 500,
        }
      )
        .then((res) => {
          this.showAccountBalance = res.data.rows.find(
            (item) => item.setKey === "SHOW_ACCOUNT_BALANCE"
          ); // 仅解冻自由充值金额
          if (!this.showAccountBalance) {
            this.showAccountBalance = { setValue: "1" }; // 默认为是
          }
        })
        .catch(() => {});

      post(
        "api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace(
          "{businessId}",
          this.customerUser.businessId
        )
      )
        .then((result) => {
          this.paymentInfo = result.data;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    formatAmount(amount) {
      if (amount) {
        return formatAmount(amount);
      } else {
        return "0.00";
      }
    },
    changeUser() {
      this.changeShow = true;
    },
    formattimestamp1(time) {
      return formattimestamp(time);
    },
  },
};
</script>
